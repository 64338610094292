import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title>Webinar en directe Temari Mossos 2023-24 (Conceptes claus)</Title>
                <Text>
                    Tots els conceptes claus dels àmbits A, B i C
                    <br />
                    <br />
                    Un curs per acabar d'assolir tots els conceptes claus i les dates més rellevants
                    de cara a l'examen de Mossos d'Esquadra.
                    <br />
                    <br />
                    Tracta d'un curs ultra intensiu impartit per ZOOM en directe i que es queda
                    gravat per poder veure-ho en cas de no poder assistir a la sessió.
                    <br />
                    <br />
                    Si vols l'última empenta de cara a l'examen, aquest és el teu curs.
                    <br />
                    <br />A mesura que s'apropi la nova convocatòria de Mossos d'Esquadra 2024/2025
                    tornarem a portar aquesta formació.
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
